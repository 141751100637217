import React from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import '../../styles/Main/Welcome.css';

const Welcome = () => {
    return (
        <section className='welcome' id='home'>
            <div className='welcome_message'>
                <h1>Hi there! I'm David Robison.</h1>
                <h1>I'm a web developer focused on crafting impactful web solutions.</h1>
                <h1>Explore my portfolio and let's connect.</h1>
            </div>
            <a href='#about'>
                More Information
                <KeyboardArrowDownIcon />
            </a>
        </section>
    );
};

export default Welcome;
